export default {
  commonKeys: {
    skipBtn: 'Skip this',
    verifyBtn: 'Verify Account',
    errorResponseText: 'Error Response',
    upload: 'Upload',
    edit: 'Edit',
    cancel: 'Cancel',
    discount: 'Discount',
    online: 'Online',
    free: 'Free',
    apply: 'Apply',
    loadMore: 'Load more',
    loading: 'Loading',
    back: 'Back',
    search: 'Search',
    menu: 'Menu',
    close: 'Close',
    none: 'None',
    all: 'All',
    read: 'Read',
    create: 'Create',
    update: 'Update',
    delete: 'Delete',
    yes: 'Yes',
    no: 'No',
    title: 'Title',
    description: 'Description',
    view: 'View',
    publish: 'Publish',
    send: 'Send',
    remove: 'Remove',
    save: 'Save',
    active: 'Active',
    disabled: 'Disabled',
    answer: 'Answer',
    copyToClipBoard: 'Copied to Clipboard',
    successfullySaved: 'Successfully saved',
    successfullyDeleted: 'Successfully deleted',
    successfullyPublished: 'Successfully published',
    successfullyUnPublished: 'Successfully unpublished',
    successfullyCloned: 'Successfully cloned',
    successfullySent: 'Successfully sent',
    notAuthorised: 'You are not authorised to view this page',
    confirm: 'Confirm',
    archivedSuccessfully: 'Course archived successfully',
    unarchivedSuccessfully: 'Course unarchived successfully',
    successfullyUpdated: 'Successfully updated',
    successfullyCreated: 'Successfully created',
    ok: 'Ok',
    contact: 'Contact',
    continue: 'Continue',
    download: 'Download',
    enabled: 'Enabled',
    googlePlayMarket: 'Google Play Store',
    appStore: 'App store',
    submit: 'Submit',
    disable: 'Disable',
    enable: 'Enable',
    next: 'Next',
    homePage: 'Home page',
    clear: 'Clear',
    clickToSelect: 'Click to select...',
    on: 'On',
    off: 'Off',
    add: 'Add',
    noDataFound: 'No data found',
    noResultText: 'No results found',
    preview: 'Preview',
    label: 'Label',
    date: 'Date',
    skip: 'Skip',
    fieldRequired: 'Field required',
    saveAndContinue: 'Save and continue',
    archive: 'Archive',
    required: 'required',
    optional: 'optional',
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
    subscribers: 'subscribers',
    open: 'Open',
    verification: 'Verification',
    noConnection: 'Looks there is no internet connection',
    continueWithoutLogin: 'LISTEN WITHOUT LOGIN',
    noAccess: 'You need to be logged in to listen to this podcast',
    unknown: 'Unknown',
  },
  mainNav: {
    home: 'Home',
    series: 'Series',
    favorites: 'Favorites',
    library: 'Library',
  },

  login: {
    title: 'Login',
    subtitle: 'Please log in with your MedischeScholing account.',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    loginBtnText: 'Login',
    createAccountBtnText: 'Create Account',
    invalidEmailText: 'Invalid Email',
    forgotPasswordLink: 'Forgot Password',
    forgotEmailLink: 'Email forgot',
    aboutUsLink: 'About us',
    contactLink: 'Contact',
    verifyCode: 'Verify Code',
    donthave: "Don't have an account?",
    alreadyLogged: "You already logged",
    instruction1: "Medicast is a free product by MedischeScholing. Log in with your existing MedischeScholing account that also contains your BIG-number. Please note that a BIG-number can only be attached to a single account.",
    instruction2: "Did you forget the email linked to your BIG-number, please us the forgot email function and fill in your BIG-number.",
    instruction3: "If you don't have a MedischeScholing account, please register one for free.",
    loginWith: "Login with your",
  },
  logout: {
    label: 'Logout',
    dialogPromptTitle: 'Logout',
    dialogPromptMessage: 'Do you really want to logout?',
  },
  passwordRepeat: {
    min: 'min',
    minutes: 'minutes',
    need: 'need',
    label: 'Password',
    '8Chars': '8 Characters',
    passwordMatch: 'Password match',
    '1CapitalLetter': '1 Capital lletter',
    '1SpecialCharacter': '1 Special character',
    '1Number': '1 Number',
    '1SmallLetter': '1 Small letter',
    passwordRepeat: 'Repeat password',
  },
  register: {
    title: 'Sign Up',
    subtitle: 'Create a free account.',
    emailLabel: 'Email',
    iAgreeText: 'I agree to the',
    termsConditionsText: 'terms and conditions',
    createAccountBtnText: 'Create Account',
    alreadyHaveAccountText: 'I already have an account',
    invalidEmailText: 'Invalid Email',
    verifyEmailMessage: 'Link for email verification has been sent, please confirm your email-address.',
    emailVerified: 'Email successfully verified',
    termsNotAccepted: 'You need accept terms and conditions first',
  },
  resetPassword: {
    title: 'Reset Password',
    submitBtn: 'Submit',
    invalidUrl: 'The reset password URL is invalid.',
    pleaseRetry: ' Please request a new URL by clicking here.',
  },
  contact: {
    us: 'Contact us',
    subtitle: 'We try to answer all your questions within 48 hours.',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    subject: 'Subject',
    message: 'Message',
    button: 'Submit',
    contactVia: 'Contact Via',
    popupTitle: 'We will contact you very soon.',
    popupMessage: 'Thank you so much for contacting us.',
    googlePowered: 'This site is protected by reCAPTCHA Google',
    googlePrivacy: 'Privacy Policy',
    googleTerm: 'Terms of Service',
    apply: 'apply',
    faqs: 'Frequently asked questions',
    search: 'Search',
    choose: 'Choose',
    courseInvalid: {
      title: 'Course invalid contact',
      reasonLabel: 'Why is this course invalid?',
      reasonPlaceholder: 'Choose option',
      messageLabel: 'Would you like to specify what needs to be changed?',
      button: 'Submit',
      openModalButton: 'Course data incorrect',
      popupMessage: 'Thank you so much for reporting.',
      reasons: {
        option1: 'The link does not work',
        option2: 'The dates are incorrect',
        option3: 'This course is expired',
        option4: 'This course is canceled',
        otherOption: 'Other information is incorrect',
      },
    },
    courseSuggest: {
      openModalButtonText: 'Suggest a course',
      title: 'Suggest a course',
      nameLabel: 'Name',
      namePlaceholder: 'Insert your name',
      emailLabel: 'Email',
      emailPlaceholder: 'Insert your email',
      courseTitleLabel: 'Course title',
      courseTitlePlaceholder: 'Insert course title',
      courseUrlLabel: 'Course URL',
      courseUrlPlaceholder: 'Insert course url',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Insert description',
      submitButton: 'Submit',
      popupMessage: 'Thank you so much for suggesting.',
    },
    need_assistance: 'need assistance, email',
  },
  profile: {
    languageChange: 'Language changed',
    specialOffers: {
      never: 'Never',
      monthly: 'Monthly',
      weekly: 'Weekly',
      daily: 'Daily',
    },
    welcomeText: 'Welcome to',
    specialOffersLabel: 'Special offers',
    accountTitleText: 'Account',
    emailLabel: 'Email',
    resetEmailBtnText: 'Resend verification email',
    emailSentMessage: 'Email Sent successfully!',
    emailResendNotification: 'We noticed your email has not yet been verified. We sent an email containing a link to verify your email.',
    streetLabel: 'Street',
    numberLabel: 'Number',
    additionLabel: 'Addition',
    postalCodeLabel: 'Postal Code',
    cityLabel: 'City',
    salutationLabel: 'Salutation',
    titleLabel: 'Title',
    initialsLabel: 'Initials',
    firstNameLabel: 'First Name',
    insertionLabel: 'Insertion',
    lastNameLabel: 'Last Name',
    dobLabel: 'Date of Birth',
    companyNameLabel: 'Company Name',
    kvkLabel: 'Kvk Number',
    btwLabel: 'BTW Number',
    agbLabel: 'AGB Number',
    registrationLabel: 'Registration Number',
    descriptionLabel: 'Description',
    sendOffersLabel: 'I would like to receive newsletter',
    chooseFrequencyPlaceHolder: 'Choose frequency',
    sendMeOffersCheckBox: 'Send me offers about foreign courses',
    profileUpdatedMessage: 'Profile updated successfully',
    successDialogTitle: 'Success',
    dialogConfirmText: 'Ok',
    invalidBigNumberMessage: 'The bignumber cannot be found. Please make sure this is correct. If the number is less than 11 digits, try adding a 0 in front',
    bigNotFound: 'The Big number does not exist. Please, make sure you specified correct number.',
    detailsNotFound: 'Bignumber Details not found',
    bigNumberServiceDown: 'Cannot retrive big number details now, please try again later',
    currentPassword: 'Current Password',
    changePassword: 'Change Password',
    updateProfile: 'Update Profile',
    changePasswordSuccess: 'Password change successfull! Please login again to continue.',
    changeEmail: 'Change Email',
    currentEmail: 'Current Email',
    newEmail: 'New Email',
    password: 'Password',
    newPassword: 'New Password',
    enterPassword: 'Enter Password',
    verifyPassword: 'Verify Password',
    enterNewEmail: 'Enter new email',
    emailChangeSuccess: 'Your email has been changed successfully. You can now login with new email.',
    userRoles: 'User Roles',
    bignumberChangeSuccess: 'Update Success',
    deleteProfileImage: 'Remove Profile image',
    deleteImageConfirmation: 'Are you sure you want to delete this image?',
    imageDeleteSuccess: 'Profile image deleted successfully!',
    gallery: 'Gallery',
    camera: 'Camera',
    profileImage: 'Profile image',
    current: 'Current',
    minHeight: 'Minimum required height is ',
    minWidth: 'Minimum required width is ',
    deleteImage: 'Delete image',
    changeMobile: 'Change mobile',
    birthday: {
      day: 'day',
      month: 'month',
      year: 'year',
      months: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sept: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
      },
    },
    bignumberTakenDescr: {
      buttonLabel: 'Click here for more info',
      clickHere: 'click here',
      title: 'BIG-number claimed by other account',
      p1: 'Your BIG number is already registered with another email account. A BIG number can only be linked to 1 e-mail address. Are you sure this is your BIG number? You have the following options.',
      li1: 'Use the email account associated with this BIG number. If you have forgotten this, use this page {button} and enter your BIG number. An email will be sent to that account\'s email. If you have forgotten your password, use the forgot password function.',
      li2: 'Would you like to change the email of the account of this BIG number to the email you just registered with? Then you must first delete this new account and log in to the account with which the BIG number was registered. There you can change your email address. You can do this easily by pressing the button below',
      changeEmail: 'CHANGE EMAIL OF ACCOUNT BIGNUMBER {bignumber}',
      fillEmail: 'Fill exact email of previous account',
      prevEmail: 'Previous email:',
      verifyEmail: 'Verify email',
      emailConfirmed: 'Email confirmed',
      sendTransferConfirmEmail: 'Send transfer link to email',
      linkSentSuccessfully: 'An email has been sent with a link',
    },
    emailTransfer: {
      attention: 'PAY ATTENTION!',
      p1: 'You are about to change email from account {oldEmail} to email {requestedEmail}.{br}Are you sure you have access to this email account email {requestedEmail}?',
      checkbox1: 'Yes I\'m sure I have access to email account email {requestedEmail}',
      checkbox2: 'Yes, I understand that the account with email account {requestedEmail} will be deleted. I lose all data from this account (invoices, profile, courses, etc.).',
      buttonHoverTooltip: 'Please accept checkboxes first',
      submitTransferBtnText: 'CONVERT EMAIL ACCOUNT',
    },
    saveBigNumberNoVerification: 'Save BIG-number without verification',
    bignumberRemove: {
      deletePrompTitle: 'Remove profession',
      deletePrompMsg: 'Are you sure you want to <b>remove</b> your profession?',
      deletePrompMsgMultiProfession: 'Other professions connected to this bignumber, they also will be removed. <br> Are you sure you want to <b>remove</b> your profession?',
      deletePrompConfirmText: 'Remove profession',
      deletePrompCancelText: 'Cancel',
    },
    googleTwoFactorAuth: 'Google Two factor Authentication',
    twoFACodeInvalid: 'Code invalid',
    twoFACodePlaceholder: 'Google Authenticator code',
    twoFAEnterCodeEnable: 'Enter code to enable 2FA',
    twoFAEnterCodeDisable: 'Enter code to disable 2FA',
    confirmPasswordToContinue: 'Confirm password to continue',
    twoFAEnable: 'Enable 2FA',
    twoFADisable: 'Disable 2FA',
    twoFAQRModalApp: {
      authApp: 'Authenticator App',
      enterCode: 'Enter the 6 digits code',
      enterCodeToContinue: 'Please enter Google Authenticator code to continue',
      step1Label: 'Step 1: Download Google Authenticator App',
      step2Label: 'Step 2: Open the app and add a new account and insert this key',
      step3Label: 'Step 3: Use Google Authenticator App for getting code',
      saveCodeHint: 'Important! Please write down this key code and keep it in a safe place. You MUST use the key code to log in if you lose your authenticator.',
    },
    twoFALostHelper: {
      iLostMyCode: 'I lost my code',
      lostCode: 'Lost QR code?',
      lostCodeHelper: 'Go to the Google Authenticator App and setup a new account using your SAFE CODE. This safe code was displayed when configuring your 2 Factor Authentication.',
      noSafeCode: 'No safe code?',
    },
    selectBignumber: 'Select bignumber',
    chooseBigNumberFromList: 'Choose from list',
    addNewBignumber: 'Add new bignumber',
    notifications: {
      email: 'Your email has not been verified.',
      mobile: 'Your mobile number is not yet verified. Click on the VERIFY button next to your mobile number.',
      profession: ' Your profile is missing a profession. Please add a profession',
      account: ' Your profile is missing crucial information. Please add first name, last name and postalcode.',
    },
    function: 'Function',
    addCompanies: 'Add organizations',
    automaticEmails: 'Automatic emails',
    updateMailingSettingsConfirm: 'Change email preferences?',
    mainTabLabel: 'Main',
    preferencesTabLabel: 'Preferences',
    language: 'Language',
    youSubscribedTo: 'You have subscribed to',
    youUnsubscribedFrom: 'You have unsubscribed from',
    rejectMarketingTitle: 'Do not sent me any discounts, newsletters or other promotional email',
    marketingMailingDisabledDescription: 'Marketing emails is currently disabled in you settings ',
    updateRejectMarketingConfirm: 'Change email preferences?',
    rejectMarketingEmailsUpdated: 'Email preferences updated',
    allowMarketingEmails: 'Allow marketing emails',
    deleteAccount: {
      sectionTitle: 'Delete account',
      buttonText: 'Delete',
      confirmTitle: 'Deleting account',
      confirmSubtitle: 'This action will delete your account. To confirm this action you need enter your current password.',
      confirmHawebSSOSubtitle: 'This action will delete your account. To confirm this action you need enter 6 digit pin code that we sent you by email',
      confirmButton: 'Delete',
      sendingPincode: 'Sending pincode..',
    },
    subscribedCourses: 'Subscribed courses',
    favouriteCourses: 'Favourite courses',
    recentlyViewedCourses: 'Recently viewed courses',
    pincode: 'Pincode',
    profile: 'Profile',
    settings: 'Settings',
    settingsFollowTitle: 'Companies I follow',
    settingsFollowText: 'You will receive notifications of new episodes from organizations you follow.',
    settingsFavoritesTitle: 'Favorites series',
    settingsFavoritesText: 'You will receive notifications for new episodes of your favorite series.',
    settingsNotifications: 'Notifications',
    account2FA: 'ENABLE 2FA',
    personalization: 'Personalization',
    address: 'Address',
    company: 'Company',
    companyKVK: 'KVK',
    companyBTW: 'BTW',
    companyAGB: 'AGB',
    notificationsOffTitle: 'Channel notifications off',
    notificationsOffSubTitle: 'Notifications will not be received because your phone settings disabled the app to send notifications.',
    notificationsOffButton: 'GO TO SETTINGS',
    privacyDeclaration: 'Privacy declaration',
    termsAndConditions: 'Terms and Conditions',
  },
  notFound: {
    title: 'This page is not found. Please click the link below to open it in browser:',
    clickHere: 'Click here',
  },
  userProfile: {
    title: 'User Profile',
    wasCopied: 'was copied to clipboard',
    email: 'Email',
    mobile: 'Mobile number',
    updated: 'Details were updated',
  },
  link: 'Link',
  verifyProfile: {
    title: 'Verify Account',
    subtitle: 'We need to verify a few things before you can continue.',
    emailLabel: 'Email',
    postalCodeLabel: 'Postal code',
    postalCodePlaceholder: '1234 AB',
    postalCodeError: 'Invalid Format Use Ex. 1234AB',
    mobile: {
      label: 'Mobile number',
      smsLabel: 'SMS code',
      resendBtn: 'Resend',
      invalidFormatMessage: 'Invalid mobile format',
      successOtpMessage: 'Success',
      invalidOtpMessage: 'Invalid OTP',
      verify: 'Verify',
      otpFailed: 'Failed',
    },
    bigNumber: {
      label: 'Do you have a BIG number?',
      yes: 'Yes',
      no: 'No',
      title: 'BIG Number',
      verify: 'Verify BIG number',
      enter: 'enter BIG number',
      registration: 'Enter registration number',
      answer: 'Answer question first',
    },
    profession: {
      label: 'Profession',
      add: 'Add profession',
      placeholder: 'Select a Profession',
    },
    registrationLabel: 'Registration Number',
    skipConfirm: {
      message: 'Are you sure you want to skip the verification?',
      messageBody: 'This information is needed for accreditation. Is your BIG-number already registered then logout en login with that account or',
      contactUs: 'contact us.',
    },
    verifyTooltipMessage: 'Do you have bignumber? Please select first.',
    selectBignumberMessage: 'First enter your BIG number',
    selectedBignumberMessage: 'Profession is linked to bignumber. You can change it by removing or changing bignumber',
    addAnotherProfession: 'My profession is not complete',
    addNewProfession: 'Add new profession',
    addEditProfessions: 'Add/edit professions',
    noProfessions: 'No professions',
    myProfessions: 'My professions',
    oneProfessionRequired: 'At least one profession required',
    yourProfession: 'Your profession',
    splitSelection: {
      title: 'Choose one  of the following',
      subtitle: 'The profession based on your BIG-number is automaticlly retrieved from the BIG-registry. Not all BIG-registered professions are registered in the BIG-regidter. For example, if are you resident then we cannot find this in information in the BIG-registry. You can add this information by adding an addition yo your BIG-profession.',
      addAddition: 'Add addition to big-profession',
      addAdditionHint: 'If your profession is not in this list it means its not an addition to the BIG-registry. You can add any other medical profession as a seperate prefession.',
      addAnotherBig: 'Add another big-number',
      addNonBig: 'Add non big-number profession',
    },
  },
  home: {
    featured: 'Featured',
    new: 'New releases',
    recently: 'Recently played',
    continueWatching: 'Continue watching',
    goodMorning: 'Good morning',
    goodAfternoon: 'Good afternoon',
    goodNight: 'Good night',
    view: 'view all',
    following: 'Following',
    ownPodcast: 'Want to make your own podcast?',
    topStreamTitle: 'Top 10 podcasts',
    topCoursesTitle: 'Most popular channels',
    newPodcastReleases: 'Latest podcasts',
    recommendedStreams: 'Recommended',
  },
  podcast: {
    played: 'Played',
    minLeft: 'min left',
    left: 'left',
    min: 'min',
    episodes: 'episodes',
    favorites: 'favorites',
    series: 'series',
    readMore: 'Read More',
    readLess: 'Read Less',
    allEpisodes: 'All episodes',
    recently: 'Recently played',
    local: 'en-GB',
    noPermission: 'Sorry, this podcast is only available for registered users. Please download the app and register/login with your MedischeScholing account. You can also listen through the browser.',
  },

  player: {
    chapters: 'Chapters',
    audioNotAvailableAlert: 'Sorry, this audio is not available',
  },
  versionAlert: {
    title: 'New version is available',
    hardUpdateSubtitle: 'To continue please update application',
    description: 'There is a new version available. Please update',
  },
  library: {
    library: 'LIBRARY',
    new: 'NEW',
    accreditation: 'ACCREDITATION',
    accreditationPlaceholder: 'You don\'t have completed accreditation streams.',
  },
  following: {
    following: 'FOLLOWING',
    favorites: 'FAVORITES',
    follow: 'FOLLOW',
    unfollow: 'UNFOLLOW',
    followPlaceholder: 'You are not following any organisation. When you follow organisations you can receive notifications when a new podcast is published.',
    favoritePlaceholder: 'You don\'t have any podcast saved as favorite. You can favorite a channel or a single podcast. You can use the favorites to store the podcast you liked the most and would like to listen to again. Also you can receive updates of new episodes.',
  },
  shareModal: {
    share: 'Share podcast',
    share_text: 'Share',
    share_link: 'Share link',
    more_options: 'More options',
    share_title: 'Please check this podcast!',
    email_subject: 'This podcast might be something for you.',
  },
  savedCourses: {
    noSavedCourses: 'There are no saved courses',
    subscribe: 'Subscribe',
    removeCourse: 'Remove',
    successDialogTitle: 'Success',
    courseSavedMessage: 'This course has been saved to your favorite courses',
    dialogConfirmText: 'Ok',
    dialogCancelText: 'Cancel',
    dialogPromptMessage: 'Are you sure you want to remove this course from favourites?',
    dialogPromptTitle: 'Remove favourite course',
    loginToSaveCourse: 'Please login to save the course',
  },
  followCompanies: {
    followingCompany: 'Following company',
    successfullyFollowed: 'Successfully followed',
    removedFromFollowed: 'Removed from followed',
    deletePromptTitle: 'Unfollow company',
    deletePromptMessage: 'Are you sure you want to <b>unfollow</b> this company?',
    deletePromptConfirmText: 'Yes',
    deletePromptCancel: 'Cancel',
  },
  searchPage: {
    startSearch: 'Lets find something interesting!',
    noResults: 'Nothing found with request',
    myCollection: 'My collection',
    results: 'Results',
  },
  inviteToken: {
    title: 'This app is on invite only, please enter your  unique invite token to continue',
    token: 'TOKEN',
    error: 'Token is not valid',
  },
  pushNotifications: {
    describe: {
      title: 'Hi!',
      message: 'To receive important notifications and news about released podcasts, please turn on notifications',
      actionYes: 'Turn on',
      actionNo: 'No, thanks',
    },
    disabledPrompt: {
      title: 'Notifications blocked',
      message: 'You phone has blocked all notifications. You might not receive updates of followed companies. Please go to the setting of the phone to allow the app to send notifications',
      actionYes: 'Go to settings',
      actionNo: 'Ignore',
    },
  },
  forgotPassword: {
    title: 'Forgot Password',
    subTitle: 'Type your email and we will send you a password reset link.',
    usernameLabel: 'Email',
    invalidEmailText: 'Invalid Email',
    usernameSuccessMessage: 'We have emailed your password reset link!',
    submitBtn: 'Submit',
    backToLoginLink: 'Back to Login',
    forgotEmail: 'Forgot email',
  },
  forgotEmail: {
    title: 'Forgot email',
    subTitle: 'If you do not remember your email but you do have a BIG-number, you can use it receive an reset link to the email that is register to that BIG-number',
    usernameLabel: 'Big Number',
    invalidEmailText: 'Invalid bignumber',
    forgotBigNumber: 'Forgot password',
  },
  invitationCard: {
    title: 'Invite collegue',
    text: 'Medicast is free for health care workers but an invitation code is required to register for an account. Feel free to easily share this an invitation with colleagues.',
    button: 'INVITE',
    shareText: 'Maybe Medicast is something for you. Here is an invite code: {token}. Install the app via: https://app.medicast.nl/invite/{token} or search for “Medicast“ in the app store off android or ios',
    shareInvite: 'Invite colleague',
    inviteCode: 'Your invite code',
    downloadApp: 'Download the Medicast app',
    successMessage: 'You need to paste the invite code after you installed the app.',
    installedApp: 'You have already installed Medicast.',
  },
  companyPage: {
    about: 'About',
    popular: 'Popular',
    justReleases: 'Just releases',
    series: 'Series',
  },
  accreditation: {
    points: 'Points',
    point: 'Point',
    noProfessions: 'Your profile does not have a profession. Therefore we cannot inform you about accreditation. Please complete your profile.',
    completeProfile: 'Complete profile',
    noAccreditation: 'This course is not accreditated for the profession in your profile.',
    moreInfo: 'More information',
    accreditation: 'Accreditation',
    requirements: 'Accreditation requirements',
    steps: 'You must complete the following 3 steps to be eligible for accreditation. Your accreditation will be processed within 14 days. If you have any questions, please contact us.',
    verifyBtn: 'VERIFY YOUR INFORMATION',
    checkDetails: 'We need personal information from you before we can process your accreditation request. Please check the details below from your profile.',
    request: 'REQUEST ACCREDITATION',
    verifyBtnConfirmed: 'MY INFORMATION',
    examBtn: 'START ANSWERING EXAM QUESTIONS',
    examBtn2: 'EXAM',
    examBtnPassed: 'EXAM PASSED',
    examBtnFailed: 'EXAM FAILED',
    reviewBtn: 'REVIEW QUESTIONS',
    reviewBtn2: 'REVIEW',
    reviewBtnPassed: 'REVIEW PASSED',
    pageHeader: 'ACCREDITATION QUESTIONS',
    open: 'OPEN',
    completed: 'COMPLETED',
    show: 'SHOW',
    hide: 'HIDE',
    correctAnswer: 'CORRECT ANSWER',
    wrongAnswer: 'WRONG ANSWER',
    sentAnswer: 'SENT ANSWER',
    answerSent: 'ANSWER SENT',
    answerResend: 'WRONG ANSWER PRESS HERE TO RE-SEND',
    questionAnswered: 'questions answered',
    verifyPlaceholder: 'First check the details of your accreditation.',
    congratulationPlaceholder: 'Congratulations, you passed. Your accreditation is now complete.',
    openTabPlaceholder: 'You have answered on all the questions. You can go back to the podcast.',
    completedTabPlaceholder: 'Here you will see all the questions you have answered.',
    errorData: 'Something went wrong, we cant load questions data',
    timeLeft: 'Closes in ',
    goBack: 'GO BACK TO QUESTION',
    missedQuestionAlert: 'Look out! You missed a question. Press go back to see the question again.',
    noQuestions: 'On this page the accreditation questions will appear automatically. You need to keep this page open',
    skippedTrack: 'Look out! You have not listened to part of the podcast. As a result, you cannot qualify for accreditation.',
    emailCertificate: 'Email Certificate',
  },
  streams: {
    livestreams: 'Streams',
    streamIsLiveLabel: 'Stream is live',
    create: 'Create',
    platform: 'Streaming platform',
    embedUrl: 'Embed URL',
    selectPlatform: 'Select a platform',
    selectEpisode: 'Select episode',
    rssUrl: 'RSS URL',
    noEpisodes: 'No episodes',
    chatRoom: 'Chat room',
    allowFullscreen: 'Allow full screen',
    discussionRoom: 'Discussion room',
    QandA: 'Q&A',
    playerSettings: 'Player Settings',
    images: 'Images',
    selectImage: 'Select image',
    required: 'Required',
    selectForm: 'Select form',
    forms: 'Forms',
    documents: 'Documents',
    noDocuments: 'No documents',
    selectDocument: 'Select document',
    course: 'Course',
    views: 'Views',
    subscribed: 'Subscribed',
    questions: 'Questions',
    date: 'Date',
    published: 'Published',
    created: 'Created',
    edited: 'edited',
    points: 'points',
    analytics: 'Analytics',
    createStream: 'Create stream',
    editStream: 'Edit stream',
    courseTitle: 'Course title',
    console: 'Admin console',
    remove: 'Delete',
    unpublish: 'Unpublish',
    view: 'View',
    settings: 'Settings',
    goLive: 'Go live',
    addTags: 'Add a tag',
    addThisTag: 'Add this tag',
    selectLabel: 'Press enter to select',
    deselectLabel: 'Press enter to remove',
    typeComment: 'Type your comment here',
    title: 'Title',
    discussion: 'Discussion',
    disableDiscussion: 'Disable discussion',
    sortBy: 'Sort by',
    disableChat: 'Disable chat',
    chat: 'Chat',
    showMore: 'Show more',
    showLess: 'Show less',
    QnA: 'Q&A',
    exam: 'Exam',
    poll: 'Poll',
    review: 'Review',
    vote: 'Vote',
    disableQnA: 'Disable Q&A',
    time: 'Time',
    live: 'Live',
    stream: 'Stream',
    typeATitle: 'Please type a title',
    typeAMessage: 'Please type a message',
    sortByUpVoted: 'Sort by most up voted',
    sortByDownVoted: 'Sort by most down voted',
    sortByRecent: 'Sort by most recent',
    sortByLast: 'Sort by last',
    blockUser: 'Block user',
    messageUser: 'Message user',
    reply: 'Reply',
    report: 'Report',
    message: 'Message',
    questionnaire: 'Questionnaire',
    disableQuestionnaire: 'Disable questionnaire',
    hideAllQuestions: 'Hide all questions',
    showAllQuestions: 'Show all questions',
    hideAllResults: 'Hide all results',
    showAllResults: 'View all results',
    completed: 'Completed',
    passed: 'Passed',
    displayMoments: 'Display moments',
    successDisplayOptionsSave: 'Display settings saved',
    chooseDisplayType: 'Choose display type',
    start: 'Start',
    end: 'End',
    clockTime: 'Clock time',
    playerTime: 'Player time',
    responded: 'Responded',
    results: 'Results',
    displayTimes: 'Display times',
    schedule: 'Schedule',
    createSchedule: 'Create schedule',
    video: 'Video',
    successfullySaved: 'Successfully saved',
    savedStream: 'Successfully saved',
    deletePromptTitle: 'Delete',
    deletePromptMessage: 'Are you sure you want to <strong>delete</strong> the stream?',
    deletePromptConfirmText: 'Ok',
    deletePromptCancel: 'Cancel',
    deletedStream: 'Successfully deleted',
    updatedStream: 'Successfully updated',
    unPublish: 'Unpublish',
    publish: 'Publish',
    selectImageType: 'Type',
    selectDocumentType: 'Type',
    noImages: 'No images',
    newQuestionText: 'A new question is being shown',
    ok: 'OK',
    sent: 'Sent',
    send: 'Send',
    correct: 'Correct',
    wrong: 'Wrong',
    retry: 'Retry,',
    newQuestionAppears: 'A new question is being shown',
    streamToolbar: 'Stream toolbar',
    speakers: 'Speakers',
    program: 'Program',
    info: 'Info',
    slides: 'Slides',
    messageCreated: 'Message created',
    errorCreatingMessage: 'Error creating message',
    deleteMessageConfirmation: 'Are you sure you want to delete this message?',
    leaveStreamConfirmation: 'Are you sure you want to leave the webinar?',
    display: 'Display',
    help: 'Help',
    leaveEvent: 'Leave event',
    answerRequired: 'Answer required',
    createDisplayMoment: 'Create display moment',
    notAvailable: 'Stream is not available now',
    endEvent: 'End event',
    survey: 'Survey',
    endEventPromptTitle: 'End event',
    endEventPromptMessage: 'Are you sure you want to end the event, this will take all users back to the waitingroom. They will not be able to chat or answer questions anymore.',
    refresh: 'Refresh stream view',
    refreshInterval: 'Refresh interval (sec)',
    enterNumber: 'Enter number',
    refreshButton: 'Refresh',
    successRefresh: 'Refreshed successfully',
    cantLoadData: 'Something went wrong, please try again in a few minutes. We apologize for the inconvenience. If the problems continue, please contact info@medischescholing.nl',
    pusherError: {
      mainError: 'General socket connection failed. Please refresh page or use another browser',
      publishError: 'Publish socket connection failed. Please refresh page or use another browser',
      chatError: 'Chat socket connection failed. Please refresh page or use another browser',
      qnaError: 'QnA socket connection failed. Please refresh page or use another browser',
    },
    redirectingToCourse: 'This account does not have access to the stream, you need to subscribe',
    questionnaireChanged: 'Accreditation questionnaire has been changed, your score may be updated',
    myProgram: 'My program',
    linkPlatformButtonText: 'Click here to continue',
    unknownName: 'Unknown name',
    unknownProfession: 'Unknown profession',
    nameAndProfessionRequired: 'Name and profession needs to be filled for creating messages',
    tabsPlaceholders: {
      chatViewOnly: 'Here you can read the chat. Guest accounts cannot participate in the chat',
      chatCantView: 'This account does not have permission to read chat',
      chatNoProfileLink: 'Here all participant can chat with each other. Right now no chat messages have been send yet',
      chat: 'Here all participant can chat with each other. Right now no chat messages have been send yet. Before you can participate in the chat, make sure you {0} is up to date. You can also upload a profile image.',
      qna: 'Here you can ask questions directly to the moderators of the stream. The question and the answer will only be shared between you and the moderators.',
      exam: 'During the stream, exam questions will automatically appear here. You will see a message when new questions appear. Before you can answer the questions, you will need to verify personal information for accrediation.',
      poll: 'During the stream, poll questions will automatically appear here. These questions will not influence the results of any accrediation exams.',
      vote: 'During the stream, poll questions will automatically appear here. The results are shared with the administrators. Voting is not anonymous.',
      chatGuestCantCreate: 'Guest users cannot participate in the chat.\n' +
        '                  Please login or register and verify your profile if you wish to participate in this chat.\n' +
        '                  If you have already registered your BIG-number in the past, make sure to login with that account as BIG-numbers can only be claimed once.\n' +
        '                  Contact us via info@medischescholing.nl in you have problems.',
    },
    administrator: 'administrator',
    pin: {
      pin: 'Pin',
      pinOff: 'Unpin',
      pinned: 'Successfully pinned',
      unpinned: 'Successfully unpinned',
      pinConfirm: 'Are you sure you want to <strong>pin</strong> the message?',
      pinOffConfirm: 'Are you sure you want to <strong>unpin</strong> the message?',
    },
    adminChanges: {
      displayMoments: 'Display moments has been updated by moderator',
      settings: 'Stream settings has been updated by moderator',
      liveState: 'Is live state has been updated by moderator',
      publishState: 'Publish state has been updated by moderator',
      pinnedMessage: 'Pinned messages list has been updated by moderator',
    },
    examPassedPopUpCertificate: 'Congratulations, you have passed your exam for accreditation! You can view you results and download a certificate at any time from the subscribed courses page.',
    examPassedPopUp: 'Congratulations, you have passed your exam for accreditation!',
    passedLowerCase: 'passed',
    notPassedLowerCase: 'not passed',
    openProgram: 'Open program',
    closeProgram: 'Close program',
    professionValidationReasons: {
      table: {
        title: 'Some profession not valid for accreditation',
        profession: 'Professions',
        reason: 'Reason',
        valid: 'Valid',
      },
      title: 'Accreditation alert',
      noAccreditationForProfession: 'No accreditation for this profession',
      noAccreditationForCourse: 'The course is not eligible for accreditation.',
      accreditationPossible: 'This profession can recieve accreditation',
    },
    leaveAsGuestPrompt: {
      title: 'Leave event as guest',
      message: 'If you passed exam, make sure you downloaded certificate, we wont save this data when you leave',
      confirmText: 'Leave and login',
      cancelText: 'Cancel',
    },
    downloadCertificate: 'Download certificate',
    displayMomentsModal: {
      type: 'Type',
      action: 'Action',
      showQuestion: 'Show question',
      publishResults: 'Publish results',
      publishAnswers: 'Publish answers',
      hideQuestion: 'Hide question',
      unPublishResults: 'Unpublish results',
      unPublishAnswers: 'Unpublish answers',
      noCorrectAnswer: 'No correct answer',
      hideDirectAnswer: 'Hide direct answer',
      attemptsAllowed: 'Attempts allowed',
    },
    confirmProfileChangeEmailTooltip: 'This is the email that belongs to your account. To change it, go to your profile.',
    messageMovedToQnA: 'This message is answered through Q&A.',
    receivedQnAMessage: 'You have received a personal message in Q&A',
    qnaMoveOptions: {
      title: 'Move message to QnA',
      label: 'The user will receive a Q&A message. What do you want to do with the chat message?',
      option1: 'Replace message with “This message is answered through Q&A.“',
      option2: 'Remove message in general chat.',
      option3: 'Keep message.',
    },
    chatActions: {
      answer: 'Answer',
      answerInQnA: 'Answer in QnA',
      successfullyMoved: 'Successfully moved',
    },
    linkInUrlWarning: 'Sending links is only allowed for administrators.',
    totalViews: 'Views',
    liveViews: 'Live',
    autoRefreshViews: 'Refresh views count',
    showTotalLiveViews: 'Show total live viewers',
    showTotalViews: 'Show total views',
    showTotalSubscribers: 'Show total subscribers',
    chapters: {
      chapters: 'Chapters',
      time: 'Time',
      title: 'Title',
      addManually: 'Add manually',
      addFromStream: 'Add from stream',
      noChapters: 'No chapters',
      play: 'Play',
      deletePromptTitle: 'Delete chapter',
      deletePromptMessage: 'Are you sure you want to <b>delete</b> this chapter?',
      deletePromptConfirmText: 'Delete',
      deletePromptCancelText: 'Cancel',
      chaptersWarningTitle: 'Chapters time references',
      chaptersWarningMessage: 'Warning, you are about to change the stream url. In doing this the chapter time references might not match anymore. Would you like to proceed:',
      chaptersWarningConfirm: 'Yes - proceed',
    },
    notPublished: 'Stream not published',
    rememberProgress: 'Remember progress',
    noMessages: 'No messages',
    markAsUnread: 'Mark as unread',
    hideRead: 'Hide read',
    allowCertificate: 'Allow download certificate',
    leaveWithUnansweredQuestions: 'You are about to leave this stream, but we noticed the following questions are unanswered:',
    completeQuestions: 'Complete questions',
    emailUnreadMessage: 'Email unread messages',
    emailUnreadMessageTo: 'Email unread messages to',
    streamDocuments: {
      presentation: 'Presentation',
      handout: 'Handout',
      document: 'Document',
      references: 'References',
    },
    streamAudio: {
      currentFiles: 'Current files:',
      deletePromptTitle: 'Delete audio file',
      deletePromptMessage: 'Are you sure you want to <b>delete</b> this audio?',
    },
    retryForm: 'Retry form',
  },
  examLeaveModal: {
    successTitle: 'Congratulations, you have passed the accreditation exam!',
    successSubTitle: 'Congratulations, you have passed the accreditation exam!',
    failTitle: 'Im sorry, you have not passed the accreditation exam.',
    failSubTitle: 'You cannot apply for accreditation. Please watch the video again at a later moment to retry or contact us at info@medischescholing.nl if you would like to retry the exam.',
    failSubTitleNoRetries: 'You cannot submit an accreditation request. If you have any questions, please contact us via info@medischescholen.nl',
    correct: 'Correct',
    wrong: 'Wrong',
    unAnswered: 'Unanswered',
    viewResults: 'View results',
    leave: 'Leave',
    leaveExam: 'Back',
    cancel: 'Cancel',
    questionAnswered: 'question answered',
    emailCertificate: 'Email certificate',
  },
  verifyEmail: {
    title: 'Verify Email',
    subtitle: 'We need to verify a few things before you can continue.',
    errorMessage: 'Something went wrong while verifying your email.',
    tooManyRequest: 'Too many requests, please try again later',
    pleaseLogin: 'Please login',
    linkExpired: ' to your profile and click on the “Resend verification email“ button. You will receive a new email to verify your email.',
    redirectToProfile: ' to your profile.',
  },
  onboardingTooltips: {
    followCompanyBtn: 'You can follow companies to get notifications when new episodes arrive.',
    favoriteCourseBtn: 'You can favour course easily to view them again',
  },
};
